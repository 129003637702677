import React from "react";
import { PageProps, graphql } from "gatsby";

import { Layout } from "../components/globals/Layout";
import { SEO } from "../components/globals/SEO";
import { KleinboyArticle, KleinboyTag } from "../types";
import { fromEntries } from "../utils/map";
import { ArticleList } from "../components/ArticleList";

export const query = graphql`
  query($paths: [String]!) {
    allKleinboyArticle(filter: { path: { in: $paths } }) {
      nodes {
        path
        title
        description
        tags
        publishedTime
      }
    }
    allKleinboyTag {
      nodes {
        key
        title
      }
    }
  }
`;

type DataProps = {
  allKleinboyArticle: { nodes: KleinboyArticle[] };
  allKleinboyTag: { nodes: KleinboyTag[] };
};

type PageContext = {
  year: number;
};

const TagDetail: React.FC<PageProps<DataProps, PageContext>> = ({ data, pageContext: { year } }) => {
  const tagMap = fromEntries(data.allKleinboyTag.nodes.map((n) => [n.key, n]));
  const title = `${year}年の記事`;
  return (
    <Layout>
      <SEO title={title} />
      <article>
        <section>
          <h1>{title}</h1>
          <ArticleList articles={data.allKleinboyArticle.nodes} tagMap={tagMap} />
        </section>
      </article>
    </Layout>
  );
};

export default TagDetail;
